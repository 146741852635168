<template>
  <div class="nofound-box">
    <div class="img">
      <!-- <img class="nofound-pic" src="../../assets/images/404.png"/> -->
    </div>
    <div style="padding: 30px 0;text-align: center;">
      <el-link class="btn-search" @click.prevent="submit" :underline="false">返回首页</el-link>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Error',
  methods: {
    submit() {
      this.$router.push({
        path: '/',
      });
    },
  },
};
</script>
<style lang="less" scoped>
.nofound-box {
  margin: 100px auto 0 auto;
}
.img {
  width: 500px;
  height: 351px;
  margin: auto;
}
.img img {
  display: block;
  height: 100%;
  width: 100%;
}
</style>
